// ========= REACT =========
import React from "react";

// ========= COMPONENTS =========
import styled from "styled-components";
import { Link } from "gatsby";

// ========= UTILITIES =========
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ========= TOKENS =========
import COLORS from "@deeplocal/colors/google";

// ========= STYLE =========
import "normalize.css";
import "../styles/main.css";
import "../styles/typography.css";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 100vh;
	width: 100vw;

	background: black;
	color: white;

	& a:link,
	& a:visited {
		color: white;
		text-decoration: none;
	}
`;
const Destination = styled.h2`
	padding: 1em;
	background: ${(props) => props.color};
	border-radius: var(--radius);
`;

// ========= MAIN =========
const Directory = () => {
	return (
		<Container>
			<Link to="/">
				<Destination color={COLORS.defaults.blue}>
					<span>🚚</span>
					<span style={{ padding: `1em` }}>
						Delivery Tycoon
					</span>
					<span>🚴</span>
				</Destination>
			</Link>
			<Link to="/metrics">
				<Destination color={COLORS.defaults.red}>
					<span>📉</span>
					<span style={{ padding: `1em` }}>Analytics</span>
					<span>📈</span>
				</Destination>
			</Link>
			<Link to="/?scene=SCORE">
				<Destination color={COLORS.defaults.yellow}>
					<span>💯</span>
					<span style={{ padding: `1em` }}>High Scores</span>
					<span>🏁</span>
				</Destination>
			</Link>
			<Link to="/?scene=CREDITS">
				<Destination color={COLORS.defaults.green}>
					<span>👩‍💻</span>
					<span style={{ padding: `1em` }}>
						Credits & Technologies
					</span>
					<span>👨‍💻</span>
				</Destination>
			</Link>
		</Container>
	);
};

export default Directory;
